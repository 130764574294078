import React, { useState, useEffect } from "react";
import {
  getCartItems,
  updateCartItem,
  deleteCartItem,
  calculateDiscountedAmt,
} from "../../services/APIService";
import { Link } from "react-router-dom";
import Loader from "../../services/Loader";
import { useNavigate } from "react-router-dom";
import { loadCart, sTotal } from "../../store/slices/cartSlice";
import { useSelector, useDispatch } from "react-redux";

export default function Cart() {
  const navigate = useNavigate();
  const cartDataRedux = useSelector((state) => state.cart.value);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [cartData, setCartData] = useState([]);
  const [subTotal, setSubtotal] = useState(0);
  const [charges, setCharges] = useState(50);
  const [netTotal, setNetTotal] = useState(0);

  useEffect(() => {
    loadCartItems();
  }, []);

  const loadCartItems = async () => {
    try {
      setIsLoading(true);
      const response = await getCartItems();
      console.log(response);
      dispatch(loadCart(response));
      let sub_Total = 0;
      for (let i = 0; i < response.length; i++) {
        sub_Total += calculateDiscountedAmt(response[i]) * response[i].qty;
      }
      dispatch(sTotal(sub_Total));
      setCartData(response);
      setIsLoading(false);
      if (response.length > 0) checkoutCalculation(response);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const checkoutCalculation = (data) => {
    var sTotal = 0;
    for (let i = 0; i < data.length; i++) {
      var total = calculateDiscountedAmt(data[i]);
      sTotal += total * data[i].qty;
    }
    setSubtotal(sTotal);
    setNetTotal(sTotal + charges);
  };

  const updateQty = async (flag, item) => {
    try {
      var data;
      setIsLoading(true);
      if (flag === "add") {
        data = {
          cartId: item.cartId,
          qty: item.qty + 1,
          vendorVariantId: item.vendor_variant_id,
        };
      } else {
        if (item.qty > 1) {
          data = {
            cartId: item.cartId,
            qty: item.qty - 1,
            vendorVariantId: item.vendor_variant_id,
          };
        } else {
          deleteCart(item);
        }
      }
      const response = await updateCartItem(data);
      console.log(response);
      setCartData(response);
      loadCartItems();
      setShowMsg(true);
      setMsgClass("alert alert-success alert-dismissible fade show");
      setMsgText(response?.message);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const deleteCart = async (item) => {
    try {
      if (window.confirm("Remove product from cart..?")) {
        setIsLoading(true);
        var data = {
          cartId: item.cartId,
          qty: item.qty - 1,
          vendorVariantId: item.vendor_variant_id,
        };
        const response = await deleteCartItem(data);
        console.log(response);
        setCartData(response);
        loadCartItems();
        setIsLoading(false);
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response?.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const proccessToCheckout = () => {
    var data = {
      cartData: cartData,
      subTotal: subTotal,
      charges: charges,
      netTotal: netTotal,
    };
    navigate("/checkout", { state: data });
  };

  return (
    <div className="pt-140">
      {isLoading && <Loader />}

      <div
        className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
        data-bg="img/bg/9.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                <div className="section-title-area ltn__section-title-2">
                  <h1 className="section-title white-color">Cart</h1>
                </div>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li>Cart</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="liton__shoping-cart-area mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {showMsg && (
                <div className={msgClass} role="alert">
                  <strong>{msgText}</strong>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    {/* <span className="float-right" aria-hidden="true">
                      ×
                    </span> */}
                  </button>
                </div>
              )}

              {cartData.length > 0 ? (
                <div className="shoping-cart-inner">
                  <div className="shoping-cart-table table-responsive">
                    <table className="table">
                      <tbody>
                        {cartData &&
                          cartData.map((item, i) => (
                            <tr key={i}>
                              <td className="cart-product-image">
                                <a href="javascript:void(0)">
                                  <img src="img/product/1.png" alt="#" />
                                </a>
                              </td>
                              <td className="cart-product-info text-left">
                                <h4 style={{ width: 400 }}>
                                  <a href="javascript:void(0)">
                                    {item.productName}
                                  </a>
                                </h4>
                              </td>
                              <td className="cart-product-price">
                                ₹ {calculateDiscountedAmt(item)}
                              </td>
                              <td className="cart-product-quantity">
                                <div className="cart-plus-minus">
                                  <div
                                    onClick={() => updateQty("remove", item)}
                                    className="dec qtybutton"
                                  >
                                    -
                                  </div>
                                  <input
                                    type="text"
                                    value={item.qty}
                                    name="qtybutton"
                                    className="cart-plus-minus-box"
                                  />
                                  <div
                                    onClick={() => updateQty("add", item)}
                                    className="inc qtybutton"
                                  >
                                    +
                                  </div>
                                </div>
                              </td>
                              <td className="cart-product-subtotal">
                                ₹{calculateDiscountedAmt(item) * item.qty}
                              </td>
                              <td
                                onClick={() => deleteCart(item)}
                                className="cart-product-remove"
                              >
                                x
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="shoping-cart-total mt-50">
                    <h4>Cart Totals</h4>
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Cart Subtotal</td>
                          <td>₹{subTotal}</td>
                        </tr>
                        <tr>
                          <td>Shipping and Handing</td>
                          <td>₹{charges}</td>
                        </tr>
                        {/* <tr>
                          <td>Vat</td>
                          <td>$00.00</td>
                        </tr> */}
                        <tr>
                          <td>
                            <strong>Order Total</strong>
                          </td>
                          <td>
                            <strong>₹{netTotal}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="btn-wrapper text-right text-end mt-3">
                      <button
                        className="theme-btn-1 btn btn-effect-1"
                        type="button"
                        onClick={proccessToCheckout}
                      >
                        Proceed to checkout
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <h4>Your cart is empty..!</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
