import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../services/Loader";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { createAccount, verifyAccount } from "../../services/APIService";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../store/slices/userSlice";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [btnText, setBtnText] = useState("Submit");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, isOtpSent]);

  const onSubmit = async (data) => {
    try {
      if (btnText == "Submit") {
        sendOTP(data);
      } else {
        verifyOTP(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendOTP = async (data) => {
    try {
      setIsLoading(true);
      const response = await createAccount(data.phoneNumber);
      if (response == "OTP sent successfully.") {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response);
        setBtnText("Verify");
        setIsOtpSent(true);
        setSeconds(30);
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };
  const verifyOTP = async (data) => {
    try {
      setIsLoading(true);
      setShowMsg(false);
      const response = await verifyAccount(data);
      if (response.encriptedToken) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText("OTP Matched..!");
        dispatch(login(response));
        localStorage.setItem("UserToken", JSON.stringify(response));
        navigate("/user");
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText(error?.response?.data);
    }
  };

  return (
    <div className="pt-140">
      {isLoading && <Loader />}

      <div
        className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
        data-bg="img/bg/9.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                <div className="section-title-area ltn__section-title-2">
                  {/* <h6 className="section-subtitle ltn__secondary-color">
                    // Welcome to our company
                  </h6> */}
                  <h1 className="section-title white-color">Account</h1>
                </div>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li>Login</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__login-area pb-65">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area text-center">
                <h1 className="section-title">Sign In / Sign Up</h1>
                {/* <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.{" "}
                  <br />
                  Sit aliquid, Non distinctio vel iste.
                </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="account-login-inner">
                <form
                  className="ltn__form-box contact-form-box"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {showMsg && (
                    <div
                      className={msgClass}
                      //className="alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>{msgText}</strong>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        {/* <span className="float-right" aria-hidden="true">
                      ×
                    </span> */}
                      </button>
                    </div>
                  )}
                  <div className="mb-3">
                    {" "}
                    <input
                      type="text"
                      placeholder="Mobile No"
                      {...register("phoneNumber", {
                        required: "Enter mobile no",
                        minLength: {
                          value: 10,
                          message: "Enter valid mobile no",
                        },
                        maxLength: {
                          value: 10,
                          message: "Enter valid mobile no",
                        },
                        pattern: {
                          message: "Enter valid mobile no",
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        },
                      })}
                    />
                    {errors?.phoneNumber && (
                      <p className="text-danger">
                        {errors?.phoneNumber.message}
                      </p>
                    )}
                  </div>

                  {isOtpSent && (
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="OTP"
                        {...register("otp", {
                          required: "Enter otp",
                        })}
                      />
                      {errors?.otp && (
                        <p className="text-danger">{errors?.otp.message}</p>
                      )}
                      <div>
                        {seconds > 0 || minutes > 0 ? (
                          <span>
                            Time Remaining:{" "}
                            {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </span>
                        ) : (
                          <span className="d-flex">
                            Didn't recieve code?
                            <p
                              role="button"
                              className="mb-0 fw-bold ms-2"
                              onClick={() => {
                                sendOTP({
                                  phoneNumber: getValues("phoneNumber"),
                                });
                              }}
                            >
                              {" "}
                              Resend OTP
                            </p>
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="btn-wrapper">
                    <button className="theme-btn-1 btn btn-block" type="submit">
                      {btnText}
                    </button>
                  </div>
                  {/* <div className="go-to-btn mt-20">
                    <a href="#">
                      <small>FORGOTTEN YOUR PASSWORD?</small>
                    </a>
                  </div> */}
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="account-create text-center pt-50">
                <h4>DON'T HAVE AN ACCOUNT?</h4>
                <p>
                  Add items to your wishlistget personalised recommendations{" "}
                  <br />
                  check out more quickly track your orders register
                </p>
                {/* <div className="btn-wrapper">
                  <a href="register.html" className="theme-btn-1 btn black-btn">
                    CREATE ACCOUNT
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
