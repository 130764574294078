import React, { useState, useEffect } from "react";
import { getAllOrders } from "../../services/APIService";
import Loader from "../../services/Loader";

export default function Orders() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [orderDetails, setOrderDetails] = useState();
  const [subTotal, setSubtotal] = useState(0);
  const [netTotal, setNetTotal] = useState(0);

  useEffect(() => {
    loadOrderData();
  }, []);

  const discountedAmt = (data) => {
    var discountAmt = (data.price * data.discountAmount) / 100;
    var discountedAmt = data.price - discountAmt;
    return discountedAmt;
  };

  const discountAmt = (data) => {
    var discountAmt = (data.price * data.discountAmount) / 100;
    return discountAmt;
  };

  const DetailsCalculation = (data) => {
    let oDetails = data?.orderDetails;
    var sTotal = 0;
    for (let i = 0; i < oDetails?.length; i++) {
      var total = discountedAmt(oDetails[i]);
      sTotal += total * oDetails[i].qty;
    }
    setSubtotal(sTotal);
    setNetTotal(sTotal + data.shippingCost);
  };

  const loadOrderData = async () => {
    try {
      setIsLoading(true);
      const response = await getAllOrders();
      console.log(response);
      setOrderData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const loadOrderDetails = async (item) => {
    try {
      DetailsCalculation(item);
      setOrderDetails(item);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="ltn__myaccount-tab-content-inner">
      <div className="table-responsive">
        <h4>All Orders</h4>
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Order ID</th>
              <th>Order No</th>
              <th>Payment Mode</th>
              <th>Order Status</th>
              {/* <th>Total</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {orderData &&
              orderData.map((item, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{item.orderId}</td>
                  <td>{item.orderNumber}</td>
                  <td>{item.paymentMethode}</td>
                  <td>{item.status}</td>
                  {/* <td>
                    {item.orderDetails
                      .map((item1) => item1.price)
                      .reduce((acc, curr) => acc + curr, 0)}
                  </td> */}

                  <td>
                    <a
                      class="theme-btn-1 btn btn-effect py-0 px-3"
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#quick_view_modal"
                      onClick={() => loadOrderDetails(item)}
                    >
                      View Details
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div class="ltn__modal-area ltn__quick-view-modal-area">
        <div class="modal fade" id="quick_view_modal" tabindex="-1">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                  {/* <!-- <i class="fas fa-times"></i> --> */}
                </button>
              </div>
              <div class="modal-body">
                <div class="ltn__quick-view-modal-inner">
                  <div class="modal-product-item">
                    <div class="row">
                      <div class="col-lg-12 col-12">
                        <div class="modal-product-info">
                          <h3>Order Details : #{orderDetails?.orderId}</h3>

                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Status</th>
                                <th>Qty</th>
                                <th>Discount</th>
                                <th>Price</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderDetails?.orderDetails &&
                                orderDetails?.orderDetails.map((item, i) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.productName}</td>
                                    <td>{item.status}</td>
                                    <td>{item.qty}</td>
                                    <td>
                                      ({item.discountAmount}%)- ₹
                                      {discountAmt(item)}
                                    </td>
                                    <td>₹{discountedAmt(item)}</td>
                                    <td>₹{discountedAmt(item) * item.qty}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          <hr />
                          <h3>Sub Total : ₹{subTotal}</h3>
                          <h3>
                            Shipping Charges : ₹{orderDetails?.shippingCost}
                          </h3>
                          <div class="product-price">
                            <span>Net Total : ₹{netTotal}</span>
                            {/* <del>$165.00</del> */}
                          </div>
                          {/* <div class="ltn__product-details-menu-2 ">
                            <a
                              href="#"
                              class="theme-btn-1 btn btn-effect-1 float-right"
                              title="Add to Cart"
                              data-bs-toggle="modal"
                              data-bs-target="#add_to_cart_modal"
                            >
                              <i class="fa fa-times-circle-o"></i>
                              <span>Close</span>
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
